import "babel-polyfill"
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import './styles/reset.scss';
import './styles/_variables.scss';
import 'element-ui/lib/theme-chalk/index.css';
import '../element-variables.scss'

import "./assets/fonts/iconfont.css"
import "./assets/src/hotcss"

// 引入swiper样式
import "swiper/dist/css/swiper.css";
//挂载swiper
Vue.use(ElementUI);
import fetch from './api/axios'

Vue.prototype.$http = fetch
Vue.config.productionTip = false
const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCEANZ6ovqfyiwo-ZIHtDH_NocC3dqbNlK0A93VzGIJ-V49T3vqiGG8EKUdF2TxwRJqxU76XnGuyVTsGRTJeLS1WZQ91KrVaBt1B3WcBI3nZIitv9eKtN-KmPIru8WVC7M9sghNkwF7yPCQLRmV5h28nCVB8fKrlGSaaGU5N_A2QwIDAQAB' // 生成公钥
import JSEncrypt from 'jsencrypt'
Vue.prototype.$encryption = function (obj){
  let encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey) // 设置公钥
  return encrypt.encrypt(obj)
}
import { Message } from 'element-ui'
router.beforeEach((to, form, next) => {
  if (to.name == 'mine' || to.name == 'reservation' ){//|| to.name == 'sweep'
    if(!localStorage.getItem('userInfo')){
      Message({
        message: '请先登录',
        type: 'error',
        duration: 2 * 1000,
      })
      setTimeout(() => {
        next('/login');
      }, 1000);
    }else{
      next();
    }
    
    
  }else{
    next();
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
